var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('heading',{attrs:{"name":"business-api","title":_vm.$t('business.seals.details.members_table.title'),"subtitle":_vm.$t('business.seals.details.members_table.subtitle')}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"large":"","color":"info"},on:{"click":function($event){_vm.selectedMembersToAdd = []
        _vm.addMembersDialog = true}}},[_vm._v("\n      "+_vm._s(_vm.$t('global.add'))+"\n    ")]):_vm._e()],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1 mb-4",attrs:{"header-props":{
      'sort-icon': '$vuetify.icons.arrow_down',
    },"headers":_vm.membersTableHeader,"items":_vm.membersTableData,"loading":_vm.loading,"hide-default-footer":_vm.membersTableData.length < 7,"hide-default-header":_vm.$vuetify.breakpoint.xsOnly || _vm.membersTableData.length === 0,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('strong',{staticClass:"text--text float-left"},[_vm._v(_vm._s(_vm.$t('business.seals.details.members_table.empty_list')))])]},proxy:true},{key:`item.options`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":"","color":"error","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"float-right | upload__remove link",attrs:{"icon":"","color":"error","ripple":false},on:{"click":function($event){;(_vm.deleteMemberDialog = true), (_vm.memberToDelete = item)}}},on),[_c('v-icon',{attrs:{"color":"text"}},[_vm._v(" $vuetify.icons.c_close ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('global.delete.delete')))])])]}}],null,true)}),_vm._v(" "),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticClass:"mt-2",attrs:{"block":"","large":"","color":"info"},on:{"click":function($event){_vm.selectedMembersToAdd = []
      _vm.addMembersDialog = true}}},[_vm._v("\n    "+_vm._s(_vm.$t('global.add'))+"\n  ")]):_vm._e(),_vm._v(" "),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.addMembersDialog),callback:function ($$v) {_vm.addMembersDialog=$$v},expression:"addMembersDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("\n        "+_vm._s(_vm.$t('business.seals.details.members_table.add_dialog.title'))+"\n      ")]),_vm._v(" "),_c('v-card-text',[_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('business.seals.details.members_table.add_dialog.text'))+"\n        ")]),_vm._v(" "),_c('v-autocomplete',{attrs:{"items":_vm.assignableMembers,"item-text":obj => obj.email,"label":_vm.$t('global.email'),"multiple":"","filled":"","chips":"","no-data-text":_vm.$t('business.seals.details.members_table.add_dialog.no_data')},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({key:data.item.email,attrs:{"input-value":data.selected,"disabled":data.disabled,"close":"","small":"","color":"primary"},on:{"click":data.select,"click:close":function($event){return _vm.removeMemberFromAutocomplete(data.item)}}},'v-chip',data.attrs,false),[_vm._v("\n              "+_vm._s(data.item.email)+"\n            ")])]}}]),model:{value:(_vm.selectedMembersToAdd),callback:function ($$v) {_vm.selectedMembersToAdd=$$v},expression:"selectedMembersToAdd"}})],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{attrs:{"x-large":"","color":"info","outlined":""},on:{"click":function($event){_vm.addMembersDialog = false
            _vm.selectedMembersToAdd = []}}},[_vm._v("\n          "+_vm._s(_vm.$t('global.cancel'))+"\n        ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"x-large":"","color":"info","loading":_vm.loading,"disabled":_vm.loading || _vm.selectedMembersToAdd.length === 0},on:{"click":function($event){_vm.addMembers()
            _vm.addMembersDialog = false}}},[_vm._v("\n          "+_vm._s(_vm.$t('global.add'))+"\n        ")])],1)],1)],1),_vm._v(" "),(_vm.memberToDelete)?_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.deleteMemberDialog),callback:function ($$v) {_vm.deleteMemberDialog=$$v},expression:"deleteMemberDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("\n        "+_vm._s(_vm.$t('business.seals.details.members_table.delete_dialog.title'))+"\n      ")]),_vm._v(" "),_c('v-card-text',[_vm._v("\n        "+_vm._s(_vm.$t('business.seals.details.members_table.delete_dialog.text'))+"\n        "),_c('v-text-field',{staticClass:"mt-6",attrs:{"disabled":true,"label":_vm.$t('global.name'),"name":"name","maxlength":"100","filled":"","clearable":"","persistent-hint":""},model:{value:(_vm.memberToDelete.name),callback:function ($$v) {_vm.$set(_vm.memberToDelete, "name", $$v)},expression:"memberToDelete.name"}})],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),(!_vm.loading)?_c('v-card-actions',[_c('v-btn',{attrs:{"x-large":"","color":"error","outlined":""},on:{"click":function($event){_vm.deleteMemberDialog = false}}},[_vm._v("\n          "+_vm._s(_vm.$t('global.cancel'))+"\n        ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"x-large":"","color":"error","disabled":_vm.loading,"loading":_vm.loading},on:{"click":function($event){_vm.deleteMember()
            _vm.deleteMemberDialog = false}}},[_vm._v("\n          "+_vm._s(_vm.$t('business.seals.details.members_table.delete_dialog.delete'))+"\n        ")])],1):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }