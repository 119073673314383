var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('heading',{attrs:{"name":"business-api","title":_vm.$t('business.seals.details.api_keys_table.title'),"subtitle":_vm.$t('business.seals.details.api_keys_table.subtitle')}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"large":"","color":"info"},on:{"click":function($event){_vm.selectedApiKeysToAdd = []
        _vm.addApiKeysDialog = true}}},[_vm._v("\n      "+_vm._s(_vm.$t('global.add'))+"\n    ")]):_vm._e()],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1 mb-4",attrs:{"footer-props":{
      'items-per-page-options': [3, 6, 24, -1],
      'items-per-page-text': _vm.$t('global.rows_per_page'),
    },"header-props":{
      'sort-icon': '$vuetify.icons.arrow_down',
    },"headers":_vm.apiKeysTableHeader,"items":_vm.apiKeysTableData,"loading":_vm.loading,"hide-default-footer":_vm.apiKeysTableData.length < 4,"hide-default-header":_vm.$vuetify.breakpoint.xsOnly || _vm.apiKeysTableData.length === 0,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('strong',{staticClass:"text--text float-left"},[_vm._v(_vm._s(_vm.$t('business.seals.details.api_keys_table.empty_list')))])]},proxy:true},{key:`item.options`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":"","color":"error","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"float-right | upload__remove link",attrs:{"icon":"","color":"error","ripple":false},on:{"click":function($event){;(_vm.deleteApiKeyDialog = true), (_vm.apiKeyToDelete = item.apiUsername)}}},on),[_c('v-icon',{attrs:{"color":"text"}},[_vm._v(" $vuetify.icons.c_close ")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('global.delete.delete')))])])]}}],null,true)}),_vm._v(" "),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticClass:"mt-2",attrs:{"block":"","large":"","color":"info"},on:{"click":function($event){_vm.selectedApiKeysToAdd = []
      _vm.addApiKeysDialog = true}}},[_vm._v("\n    "+_vm._s(_vm.$t('global.add'))+"\n  ")]):_vm._e(),_vm._v(" "),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.addApiKeysDialog),callback:function ($$v) {_vm.addApiKeysDialog=$$v},expression:"addApiKeysDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("\n        "+_vm._s(_vm.$t('business.seals.details.api_keys_table.add_dialog.title'))+"\n      ")]),_vm._v(" "),_c('v-card-text',[_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('business.seals.details.api_keys_table.add_dialog.text'))+"\n        ")]),_vm._v(" "),_c('v-autocomplete',{attrs:{"items":_vm.assignableApiKeys,"label":_vm.$t('global.api_key'),"multiple":"","filled":"","chips":"","no-data-text":_vm.$t('business.seals.details.api_keys_table.add_dialog.no_data')},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({key:data.item,attrs:{"input-value":data.selected,"disabled":data.disabled,"close":"","small":"","color":"primary"},on:{"click":data.select,"click:close":function($event){return _vm.removeApiKeyFromAutocomplete(data.item)}}},'v-chip',data.attrs,false),[_vm._v("\n              "+_vm._s(data.item)+"\n            ")])]}}]),model:{value:(_vm.selectedApiKeysToAdd),callback:function ($$v) {_vm.selectedApiKeysToAdd=$$v},expression:"selectedApiKeysToAdd"}})],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{attrs:{"x-large":"","color":"info","outlined":""},on:{"click":function($event){_vm.addApiKeysDialog = false}}},[_vm._v("\n          "+_vm._s(_vm.$t('global.cancel'))+"\n        ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"x-large":"","color":"info","loading":_vm.loading,"disabled":_vm.loading || _vm.selectedApiKeysToAdd.length === 0},on:{"click":function($event){_vm.addApiKeys()
            _vm.addApiKeysDialog = false}}},[_vm._v("\n          "+_vm._s(_vm.$t('global.add'))+"\n        ")])],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.deleteApiKeyDialog),callback:function ($$v) {_vm.deleteApiKeyDialog=$$v},expression:"deleteApiKeyDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("\n        "+_vm._s(_vm.$t('business.seals.details.api_keys_table.delete_dialog.title'))+"\n      ")]),_vm._v(" "),_c('v-card-text',[_vm._v("\n        "+_vm._s(_vm.$t('business.seals.details.api_keys_table.delete_dialog.text'))+"\n        "),_c('v-text-field',{staticClass:"mt-6",attrs:{"disabled":true,"label":_vm.$t('global.api_key'),"name":"apiKey","maxlength":"100","filled":"","clearable":"","persistent-hint":""},model:{value:(_vm.apiKeyToDelete),callback:function ($$v) {_vm.apiKeyToDelete=$$v},expression:"apiKeyToDelete"}})],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),(!_vm.loading)?_c('v-card-actions',[_c('v-btn',{attrs:{"x-large":"","color":"error","outlined":""},on:{"click":function($event){_vm.deleteApiKeyDialog = false}}},[_vm._v("\n          "+_vm._s(_vm.$t('global.cancel'))+"\n        ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"x-large":"","color":"error","disabled":_vm.loading,"loading":_vm.loading},on:{"click":function($event){_vm.deleteApiKey()
            _vm.deleteApiKeyDialog = false}}},[_vm._v("\n          "+_vm._s(_vm.$t('business.seals.details.api_keys_table.delete_dialog.delete'))+"\n        ")])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }