<template>
  <div class="image-preview">
    <div class="d-flex justify-center align-center | image-preview__stage">
      <v-img
        v-if="imageObject"
        :data-cy="imageName"
        contain
        :src="imageObject.content"
        :max-height="maxHeight"
        :max-width="maxWidth"
      />
      <div v-else :data-cy="imageName">
        {{ $t('businesses.seals.details.form_details.upload_image.info') }}
      </div>
    </div>
    <image-cropper
      :coloring="coloring"
      :cropper-title="cropperTitle"
      :image-object="imageObject"
      :upload-image-dispatch="uploadImageDispatch"
      :set-image-dispatch="setImageDispatch"
      :delete-image-dispatch="deleteImageDispatch"
      :end-point-url="endPointUrl"
      :aspect-width="maxWidth"
      :aspect-height="maxHeight"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import ImageCropper from '~/components/ImageCropper.vue'

export default Vue.extend({
  components: {
    ImageCropper,
  },
  props: {
    coloring: {
      type: Boolean,
      default: true,
    },
    cropperTitle: {
      type: String,
      required: true,
    },
    imageName: {
      type: String,
      required: true,
    },
    imageObject: {
      type: Object || null,
    },
    maxFileUploads: {
      type: Number,
      default: 15,
    },
    maxHeight: {
      type: Number,
      default: 120,
    },
    maxWidth: {
      type: Number,
      default: 305,
    },
    minHeight: {
      type: Number,
      default: 120,
    },
    minWidth: {
      type: Number,
      default: 120,
    },
    endPointUrl: {
      type: String,
      required: true,
    },
    uploadImageDispatch: {
      type: String,
      required: true,
    },
    setImageDispatch: {
      type: String,
      required: true,
    },
    deleteImageDispatch: {
      type: String,
      required: true,
    },
  },
})
</script>

<style lang="sass">
$ns: image-preview

.#{$ns}
  +skribble-box-shadow
  border: 10px solid $c-white
  border-radius: 3px

  &__stage
    min-height: 240px
    padding: 34px 32px
    background-color: $c-grey-fine
    +maw(xs)
      padding-top: 32px
      padding-bottom: 32px

  &__upload
    display: flex
    flex-direction: column
    align-items: center
    max-width: 100%
    +max-width
      width: 100%
</style>
