var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"img-cro"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"640"},model:{value:(_vm.imageModal),callback:function ($$v) {_vm.imageModal=$$v},expression:"imageModal"}},[_c('v-card',{staticClass:"img-cro-dialog__card",attrs:{"drop-padding":true}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("\n        "+_vm._s(_vm.cropperTitle)+"\n      ")]),_vm._v(" "),_c('v-card-text',{staticClass:"pa-0"},[_c('v-layout',[(_vm.uploadedImage)?_c('cropper',{staticClass:"upload-cropper",attrs:{"src":_vm.uploadedImage,"stencil-props":{
              handlers: {
                eastNorth: true,
                north: false,
                westNorth: true,
                west: false,
                westSouth: true,
                south: false,
                eastSouth: true,
                east: false,
              },
            }},on:{"change":_vm.changeStencil}}):_vm._e()],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"color":"info","outlined":""},on:{"click":_vm.clearLocalImage}},[_vm._v("\n          "+_vm._s(_vm.$t('global.cancel'))+"\n        ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{class:_vm.$vuetify.breakpoint.xsOnly ? 'order-first' : '',attrs:{"color":"info","block":_vm.$vuetify.breakpoint.xsOnly},on:{"click":_vm.uploadImage}},[_vm._v("\n          "+_vm._s(_vm.$t('global.save'))+"\n        ")])],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{staticClass:"flex-grow-1 mx-0 mb-0 | img-cro__upload-btn",attrs:{"text":"","large":""},on:{"click":function($event){return _vm.$refs.fileInput.click()}}},[_c('span',[_c('input',{ref:"fileInput",attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.loadLocalImage($event)}}}),_vm._v(" "),(!_vm.imageObject)?[_c('v-icon',{staticClass:"d-none d-sm-flex"},[_vm._v("$vuetify.icons.circle_simple_up")]),_vm._v("\n          "+_vm._s(_vm.$t('profile.visual_signature.upload.upload_label'))+"\n        ")]:[_c('v-icon',{staticClass:"d-none d-sm-flex",attrs:{"left":""}},[_vm._v("$vuetify.icons.photo")]),_vm._v("\n          "+_vm._s(_vm.$t('profile.visual_signature.upload.reset_label'))+"\n        ")]],2)]),_vm._v(" "),(_vm.imageObject)?_c('v-btn',{staticClass:"flex-grow-1 mr-0 mb-0",attrs:{"text":"","large":""},on:{"click":function($event){return _vm.deleteImage()}}},[_c('v-icon',{staticClass:"d-none d-sm-flex",attrs:{"left":""}},[_vm._v(" $vuetify.icons.bin ")]),_vm._v("\n      "+_vm._s(_vm.$t('profile.visual_signature.upload.delete_label'))+"\n    ")],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }