import "core-js/modules/es.number.constructor.js";
import Vue from 'vue';
import { Cropper } from 'vue-advanced-cropper';
export default Vue.extend({
  name: 'ImageCropper',
  components: {
    Cropper: Cropper
  },
  props: {
    cropperTitle: {
      type: String,
      required: true
    },
    imageObject: {
      type: Object || undefined
    },
    aspectWidth: {
      type: Number,
      default: 388 // 2 * dimensions.image.width defined in VisualSignature.vue
    },
    aspectHeight: {
      type: Number,
      default: 72 // 2 * dimensions.image.height defined in VisualSignature.vue
    },
    thresholdBase: {
      type: Number,
      default: 110 // start threshold for image thresholding
    },
    coloring: {
      type: Boolean,
      default: true
    },
    thresholdColor: {
      type: Array,
      default: function _default() {
        return [41, 61, 102];
      } // decimal RGB of Skribble dark grey
    },
    thresholdIterationStep: {
      // step size of threshold adjustments
      type: Number,
      default: 20
    },
    thresholdMaxIterations: {
      // max number of iterations of threshold adjustments. more than 7 makes no sense at a step of 20 because the max value of a pixel is 255
      type: Number,
      default: 7
    },
    thresholdPixelRatio: {
      // minimal ratio of dark pixels after filtering
      type: Number,
      default: 0.0025
    },
    uploadImageDispatch: {
      type: String,
      required: true
    },
    setImageDispatch: {
      type: String,
      required: true
    },
    deleteImageDispatch: {
      type: String,
      required: true
    },
    endPointUrl: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      imageModal: false,
      uploadedImage: '',
      croppedImage: {
        content: '',
        width: 0,
        height: 0
      }
    };
  },
  methods: {
    changeStencil: function changeStencil(stencil) {
      // draw image 3 times bigger than it will be rendered on the visual
      // signature canvas to have a nicer rendering
      var overSamplingFactor = 3;
      if (stencil.canvas) {
        var resizeCanvas = document.createElement('canvas');
        var stencilAspectRatio = stencil.canvas.width / stencil.canvas.height;
        var maxWidth = overSamplingFactor * this.aspectWidth;
        var maxHeight = overSamplingFactor * this.aspectHeight;
        if (maxHeight * stencilAspectRatio > maxWidth) {
          // width is limiting
          resizeCanvas.width = maxWidth;
          resizeCanvas.height = maxWidth / stencilAspectRatio;
        } else {
          // height is limiting
          resizeCanvas.height = maxHeight;
          resizeCanvas.width = maxHeight * stencilAspectRatio;
        }

        // grab the context from your destination canvas
        var destCtx = resizeCanvas.getContext('2d');
        if (destCtx) {
          // call its drawImage() function passing it the source canvas directly
          destCtx.drawImage(stencil.canvas, 0, 0, resizeCanvas.width, resizeCanvas.height);

          // do some thresholding on the pixel data to remove none-white backgrounds
          // and change color to Skribble's grey-darker (41 61 102)
          // based on https://www.html5rocks.com/en/tutorials/canvas/imagefilters/
          // and https://stackoverflow.com/questions/37512460/thresholding-base64-string-using-javascript

          // we improve on this by doing adaptive thresholding based on the
          // ratio of non-transparent pixels after the filtering to address
          // images with low contrast signature strokes which got filtered out
          // entirely

          if (this.coloring) {
            var pixels = destCtx.getImageData(0, 0, resizeCanvas.width, resizeCanvas.height);
            var darkPixelRatio = 0;
            var adaptiveThreshold = this.thresholdBase;
            var iterationCount = 0;
            while (darkPixelRatio <= this.thresholdPixelRatio && iterationCount <= this.thresholdMaxIterations) {
              pixels = destCtx.getImageData(0, 0, resizeCanvas.width, resizeCanvas.height);
              var darkPixelCount = 0;
              for (var i = 0; i < pixels.data.length; i += 4) {
                var r = pixels.data[i];
                var g = pixels.data[i + 1];
                var b = pixels.data[i + 2];
                var stroke = 0.2126 * r + 0.7152 * g + 0.0722 * b <= adaptiveThreshold;
                if (stroke) {
                  // set to dark grey
                  pixels.data[i] = this.thresholdColor[0];
                  pixels.data[i + 1] = this.thresholdColor[1];
                  pixels.data[i + 2] = this.thresholdColor[2];
                  darkPixelCount += 1;
                } else {
                  // set to transparent
                  pixels.data[i] = 255;
                  pixels.data[i + 1] = 255;
                  pixels.data[i + 2] = 255;
                  pixels.data[i + 3] = 0;
                }
              }
              // every pixel has 4 values therefore divide the length by 4 to
              // get the actual number of pixels in the image
              darkPixelRatio = darkPixelCount / pixels.data.length / 4;
              adaptiveThreshold += this.thresholdIterationStep;
              iterationCount += 1;
            }
            destCtx.putImageData(pixels, 0, 0);
          }
        }
        this.croppedImage = {
          content: resizeCanvas.toDataURL(),
          width: resizeCanvas.width,
          height: resizeCanvas.height
        };
      }
    },
    loadLocalImage: function loadLocalImage(event) {
      var _this = this;
      var input = event.target;
      if (input.files && input.files[0]) {
        this.imageModal = true;
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        reader.onload = function (e) {
          var _e$target;
          // read image as base64 and set to uploadedImage
          var res = (_e$target = e.target) === null || _e$target === void 0 ? void 0 : _e$target.result;
          if (typeof res === 'string') _this.uploadedImage = res;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    uploadImage: function uploadImage() {
      var _this2 = this;
      var data = {
        content_type: 'image/png',
        // strip off first characters 'data:image/png;base64,' if dataURL is set
        content: this.croppedImage.content !== '' ? this.croppedImage.content.substring(22) : null
      };
      this.$store.dispatch(this.uploadImageDispatch, {
        data: data,
        endPointUrl: this.endPointUrl
      }).then(function () {
        return _this2.$store.dispatch(_this2.setImageDispatch, _this2.croppedImage.content ? _this2.croppedImage : null);
      }).then(function () {
        _this2.clearLocalImage();
        _this2.$emit('changed');
      }).catch(function () {
        // TODO: handle error
      });
    },
    deleteImage: function deleteImage() {
      this.$store.dispatch(this.deleteImageDispatch, this.endPointUrl).catch(function () {
        // TODO: handle error
      });
      this.$emit('changed');
    },
    clearLocalImage: function clearLocalImage() {
      this.imageModal = false;
      this.uploadedImage = '';
      this.croppedImage = {
        content: '',
        width: 0,
        height: 0
      };
      if (this.refIsInput(this.$refs.fileInput)) this.$refs.fileInput.value = '';
    },
    refIsInput: function refIsInput(ref) {
      return !!(ref && 'value' in ref);
    }
  }
});